.link-black {
    background: #181818;
    text-decoration: none;
    border-radius: 10px;
    display: inline-block;
    padding: 18px 40px;
    color: #fff;
    font-weight: 600;
}
.product-item {
    background: #121212;
    border-radius: 40px;
}

