.zogiway {
    margin: 50px 0;
    padding: 94px 0 30px;
    border-radius: 40px;
    background: #121212;

}

.zogiway-img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border : 10px solid #101010 ;
    display: flex;
    align-items: center;
    
    margin: 10px auto ;
    justify-content: center;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 0%;
    left: 50%;
}

.gradient-prpl {
    background: linear-gradient(225deg, #6A55FE 0%, #513CE9 100%);
}
.gradient-green {
    background: linear-gradient(223.43deg, #37E7C0 -2.89%, #00BA91 100%);
}
.gradient-orange {
    background: linear-gradient(225deg, #FF6F31 0%, #E34400 100%);
}
.gradient-red {
    background: linear-gradient(225deg, #EC1B6C 0%, #D70053 100%);
}


