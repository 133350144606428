
// * ***************************
// * HEADING
// * **************************
.main-heading {
    font-size : 64px;
    font-weight : 700 ;
    line-height : 77px;
}
.heading  {
    font-size : 48px;
    font-weight : 700 ;
    line-height : 58px;
    margin-bottom : 24px;
}
.heading-sm  {
    font-size : 24px;
    font-weight : 700 ;
    line-height : 29px;
}



// * ***************************
// * TEXT
// * **************************
.text-xs  {
    font-size : 14px;
    font-weight : 400 ;
    line-height : 150%;
}
.text-sm  {
    font-size : 16px;
    font-weight : 600 ;
    line-height : 19px;
}
.text  {
    font-size : 18px;
    font-weight : 400 ;
    line-height : 150%;
}
.text-lg  {
    font-size : 24px;
    font-weight : 400 ;
    line-height : 150%;
}
.text-xl  {
    font-size : 32px;
    font-weight : 700 ;
    line-height : 38px;
}
.text-xxl  {
    font-size : 40px;
    font-weight : 400 ;
    line-height : 60px;
}
