@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600;700&display=swap');

$main-font-family: 'Urbanist', sans-serif;

// * COLORS
@import './styles/colors.scss';
@import './styles/text.scss';

body {
    margin: 0;
    background-color: $bg-color;
    color: #ffffff;
    font-family: $main-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// .swiper-button-disabled {
//     visibility: hidden !important;
// }

code {
    font-family: $main-font-family;
}

.highlight {
    position: relative;
    padding: 5px 12px;
}
.highlight::after {
    content: '';
    background-image: url('../public/images/hero/highlight.png');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.navbar-toggler {
    position: relative;
    z-index: 3;
}

.fw-bold {
    font-weight: 700;
}

.btn {
    display: inline-block;
    text-decoration: none;
    color: #ffffff;
    margin-top: 18px;
    background: #181818;
    padding: 18px 40px;
    border-radius: 10px;
}

.prpl-gradient {
    position: absolute;
    width: 10%;
    height: 100%;
    top: 50px;
    right: 0px;

    background: linear-gradient(225deg, #6a55fe 0%, #513ce9 100%);
    filter: blur(300px);
}
.member-img {
    text-align: center;
    max-width: 300px;
    max-height: 300px;
}

// .green-gradient {
//   position: absolute;
//   width: 10%;
//   height: 100%;
//   top: 50px;
//   right: 0px;
//   z-index: -1;

//   background: linear-gradient(225deg, #6A55FE 0%, #513CE9 100%);;
//   filter: blur(300px);
// }
// .prpl-gradient {
//     position: absolute;
//     width: 15%;
//     height: 50%;
//     right: -270px;
//     top: 834px;

//     background: linear-gradient(223.43deg, #37e7c0 -2.89%, #00ba91 100%);
//     filter: blur(500px);
//     border-radius: 40px;
//     z-index: -999999999999999999999999;
// }
.green-gradient {
    position: absolute;
    width: 15%;
    height: 50%;
    left: -270px;
    top: 834px;

    background: linear-gradient(223.43deg, #37e7c0 -2.89%, #00ba91 100%);
    filter: blur(500px);
    border-radius: 40px;
    z-index: -999999999999999999999999;
}
.link-sm {
    color: #6c7178;
    display: block;
    margin: 4px 0px;
    text-decoration: none;
}

.nav-support {
    position: relative;
    z-index: 333;
}

.footer-link {
    text-decoration: none;
    display: block;
    color: #fff;
    padding: 2px 0;
}

.bg-darker {
    background-color: #141414;
    padding: 90px 0;
}

.btn-green {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    background: linear-gradient(223.43deg, #37e7c0 -2.89%, #00ba91 100%);
    border-radius: 10px;
    padding: 18px 40px;
}
.btn-green:hover {
    background: #00ba91 !important;
    color: #fff !important;
}

a:hover {
    color: #00ba91 !important;
}

.link-disabled {
    color: #888;
    background-color: #131313;
}
.link-disabled:hover {
    color: #888 !important;
}

.header {
    min-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-img {
    width: 90%;
    display: block;
    margin: auto;
}

.swiper-scrollbar-drag {
    background: #3ff0c9 !important;
}

.bezogia {
    background-color: #141414;
    border-radius: 60px;
}
.bezogia-card {
    border-radius: 40px;
    background-color: #181818;
}
.text-green {
    color: #3ff0c9;
}
.secondSwipersslide {
}

.image-swiper-button-prev,
.image-swiper-button-next,
.ourgame-swiper-button-next {
    background-color: #6a55fe;
    color: white;
    border: none;
    margin: 5px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-button-disabled {
    background-color: #101010;
}
// .swiper-button-next, .swiper-button-prev{
//     position: static !important;

// }
// .swiper-slide img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }

.bezogia-slider .swiper-slide {
    height: 370px;
    width: 530px;
    background: rgba(0, 0, 0, 0.192);
    border-radius: 40px;
}

.swiper-product .swiper-slide {
    width: 550px;
    border-radius: 40px;
    margin: 10px 0;
}
.team-slider .swiper-slide {
    background-color: transparent;
}

.products-link {
    color: #eff0f0;
    text-decoration: none;
}
.products-link:hover {
    color: #fff !important;
}

@media screen and (max-width: 767px) {
    .swiper-product .swiper-slide {
        text-align: center;
        width: 100%;
        padding: 0 10px;
    }
}

.bezogia-slider .swiper-slide img {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    object-fit: cover;
}
.swiper {
    padding: 0 30px !important;
}

.swiper-slide-active {
    position: relative;
}

.btn-swiper {
    position: absolute;
    top: 50%;
    z-index: 222222222222222;
    transform: translateY(-50%);
}
.swiper .image-swiper-button-prev {
    left: calc(50% - 265px);
    transform: translate(-50%, -50%);
}
.swiper .image-swiper-button-next {
    right: calc(50% - 265px);
    transform: translate(50%, -50%);
}
.swiper .headcount-next {
    right: 25px;
    width: 40px;
    height: 40px;
}
.swiper .headcount-prev {
    left: 25px;
    width: 40px;
    height: 40px;
}
.closeIframeBtn {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-101%);
    padding: 10px;
    cursor: pointer;
}
.color-green-embed {
    color: rgb(196, 51, 51);
    background-color: rgba(0, 0, 0, 0.95);
    border-radius: 20px;
}

.embed-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    border-radius: 40px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.discordBtn {
    background: linear-gradient(225deg, #6a55fe 0%, #513ce9 100%);
}
a.discordBtn:hover {
    color: #fff !important;
    box-shadow: 0px 0px 40px #1e1e1f;
}
.cursor-pointer {
    cursor: pointer;
}
@media screen and (min-width: 1200px) {
    .hero-img {
        position: absolute;
        top: -35px;
        right: -40px;
        width: 52%;
    }
    .lege {
        width: 864px;
    }
    .lege2 {
        width: 654px;
    }
    .swiper {
        padding: 0 !important;
    }
}
@media screen and (min-width: 1400px) {
    .hero-img {
        top: -190px;
    }
}
@media screen and (min-width: 1800px) {
    .bezogia {
        padding-left: calc((100% - 1320px) / 2);
    }
    .bezogia-card {
        margin-left: 0 !important;
    }
    .pl-xxl-none {
        padding-left: 0 !important;
    }
}

@media screen and (max-width: 640px) {
    .swiper {
        padding: 0 10px !important;
    }
    .bezogia-slider .swiper-slide {
        width: 100%;
        margin-bottom: 30px;
    }
    .sm-center {
        text-align: center;
    }
}
@media screen and (min-width: 640px) {
    .legends-scroll .swiper-scrollbar {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .header {
        text-align: center;
    }
    .navbar .btn-green,
    .navbar .btn-support {
        padding: 0 !important;
        width: 44px;
        height: 44px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .navbar .btn-green img {
        width: 14px;
        height: 18px;
    }
    .navbar .btn-support {
        background-color: #00ba9228;
    }
    .navbar .btn-support img {
        width: 24px;
        height: 24px;
    }
    .main-heading {
        font-size: 40px;
        line-height: 48px;
    }
    .text-xxl {
        font-size: 24px;
        line-height: 36px;
    }

    .heading {
        font-size: 32px;
        line-height: 38px;
    }
    .partners {
        justify-content: center;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .md-center {
        text-align: center;
    }
    .partners {
        justify-content: center;
    }
    .swiper {
        display: flex;
        justify-content: center;
    }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-img {
        width: 65%;
    }
}

// * ***************************
// * Teams
// * ***************************
.hero-section {
    position: relative;
}
.hero-page-img {
    position: absolute;
    bottom: 0;
    width: 973px;
}
@media screen and (min-width: 767px) {
    .swiper-scrollbar {
        display: none;
    }
}
@media screen and (min-width: 1400px) {
    .hero-page-img {
        right: -170px;
    }
}

@media screen and (max-width: 1400px) {
    .hero-section {
        padding-bottom: 250px;
        position: relative;
    }
    .hero-page-img {
        right: 50%;
        transform: translateX(50%);
        width: 580px;
    }
}
.member-img {
    border-radius: 40px;
    overflow: hidden;
}

.learn-more {
    border-radius: 60px;
    background-color: #141414;
}

.number-usrs {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    width: 40px;
    height: 40px;
}
.headcount-slide .swiper-slide {
    background-color: transparent;
}
.headcount-slide .swiper-scrollbar,
.headcount-slide .swiper-scrollbar-drag {
    background: none !important;
}

.headcount-slide .swiper-slide {
    // width: 300px ;
    margin: 0;
    height: auto;
}

.radius-10 {
    border-radius: 10px;
}
.radius-20 {
    border-radius: 20px;
}
.radius-30 {
    border-radius: 30px;
}
.radius-40 {
    border-radius: 40px;
}
.radius-60 {
    border-radius: 60px;
}
.radius-bottom-60 {
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
}

.bg-18 {
    background-color: #181818;
}
.bg-14 {
    background-color: #141414;
}
.bg-12 {
    background-color: #121212;
}
.bg-grediant-prpl {
    background: linear-gradient(225deg, #6a55fe 0%, #513ce9 100%);
}

// * ***************************
// * products Page
// * ***************************
.product {
    background-color: #151515;
    transition: transform 0.3s, color 0.3s;
}
.product:hover {
    background-color: #191919;
    transform: scale(1.03);
}
.swiper-product .product {
    padding-top: 2rem !important;
}

@media (max-width: 767px) {
    .product:hover {
        background-color: #151515;
        transform: scale(1);
    }
}
